// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import "node_modules/font-awesome/scss/font-awesome.scss";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "./node_modules/jquery-datetimepicker/jquery.datetimepicker";
@import "./node_modules/lightbox2/dist/css/lightbox.min";

body {
    height: 100vh;

    a {
        text-decoration: none;
    }

    .btn-xs, .btn-group-xs > .btn {
        padding: 0 0.25rem;
        font-size: 0.7875rem;
        border-radius: 0.2rem;
        line-height: normal;
    }

    .btn-info {
        color: #ffffff;
    }

    .bg-gray {
        --bs-bg-opacity: 1 !important;
        background-color: #f9f9f9 !important;
    }

    .bg-primary-light {
        --bs-bg-opacity: 1 !important;
        background-color: $primary-light !important;
    }

    .bg-success-light {
        --bs-bg-opacity: 1 !important;
        background-color: $success-light !important;
    }

    .bg-danger-light {
        --bs-bg-opacity: 1 !important;
        background-color: $danger-light !important;
    }

    .bg-warning-light {
        --bs-bg-opacity: 1 !important;
        background-color: $warning-light !important;
    }

    .bg-info-light {
        --bs-bg-opacity: 1 !important;
        background-color: $info-light !important;
    }

    .text-muted {
        color: $gray-500 !important;
    }

    .text-muted-light {
        color: $gray-300 !important;
    }

    .font-weight-bold {
        font-weight: bold;
    }

    label {
        &.required:after {
            content: " *";
            color: #f44336;
            font-size: 14px;
        }
    }

    .block-link:hover {
        opacity: .8;
        cursor: pointer;
    }

    #app {
        min-height: 100vh;

        main {
            min-height: 100vh;
            margin-top: -55px;
            padding-top: calc(1.5rem + 55px);
            padding-bottom: 1.5rem;

            .full-height {
                min-height: calc(100vh - 55px - 3rem);
            }
        }
    }
}

.navbar-light {
    background-color: #5C2E91;

    .navbar-brand {
        color: #ffffff;
        position: relative;

        .logo-support {
            color: #ffeb3b;
            font-size: 0.8rem;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .navbar-nav {

        .nav-link {
            color: #ffffff;
        }
    }

    .search-top {
        border-color: #54307f;
    }

}

.search-welcome {

    max-width: 100%;

    @include media-breakpoint-up(md) {
        width: 400px;
    }

}

.scrolled-down {
    transform:translateY(-100%); transition: all 0.3s ease-in-out;
}
.scrolled-up {
    transform:translateY(0); transition: all 0.3s ease-in-out;
}

.ticket-message-body p:last-child {
    margin: 0;
}
