// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
// $blue:       #0d6efd;
// $indigo:     #6610f2;
// $purple:     #6f42c1;
// $pink:       #d63384;
// $red:        #dc3545;
// $orange:     #fd7e14;
// $yellow:     #ffc107;
// $yellow:     #f5fc61;
// $green:      #198754;
// $teal:       #20c997;
// $cyan:       #0dcaf0;
// $white:      #fff;
// $gray-100:   #f8f9fa;
// $gray-200:   #e9ecef;
// $gray-300:   #dee2e6;
// $gray-400:   #ced4da;
// $gray-500:   #adb5bd;
// $gray-600:   #6c757d;
// $gray-700:   #495057;
// $gray-800:   #343a40;
// $gray-900:   #212529;
// $black:      #000;
// $primary:    $blue;
// $secondary:  $gray-600;
// $success:    $green;
// $info:       $cyan;
// $warning:    $yellow;
// $danger:     $red;
// $light:      $gray-100;
// $dark:       $gray-900;
$blue-light:    #44cfff;
$red-light:     #f96876;
$yellow-light:  #f5fc61;
$green-light:   #57e2a2;
$cyan-light:    #b8f3ff;
$primary-light: $blue-light;
$success-light: $green-light;
$info-light:    $cyan-light;
$warning-light: $yellow-light;
$danger-light:  $red-light;
